import React from 'react';
import './About.scss';
import aboutPhoto1 from '../../assets/about/about-photo-1.png';
import aboutPhoto2 from '../../assets/about/about-photo-2.png';
import aboutPhoto3 from '../../assets/about/about-photo-3.png';
import aboutTopPhoto1 from '../../assets/about/about-photo-top-1.png';
import aboutTopPhoto2 from '../../assets/about/about-photo-top-2.jpg';
import { BackHome } from '../../components/BackHome/BackHome';

export function About() {
  return (
    <div className="about">
      <div className="about-photos">
        <div className="about-photos-top">
          <img
            src={aboutTopPhoto1}
            className="about-photos-photo-top"
            alt="about-top1"
          />
          <img
            src={aboutTopPhoto2}
            className="about-photos-photo-top"
            alt="about-top2"
          />
        </div>
        <img src={aboutPhoto1} className="about-photos-photo" alt="about1" />
        <img src={aboutPhoto2} className="about-photos-photo" alt="about2" />
        <img src={aboutPhoto3} className="about-photos-photo" alt="about3" />
      </div>
      <div className="about-text">
        <div className="about-text-title">Hi, I&apos;m Aleks!</div>
        <div className="about-text-text">
          <p className="about-text-text-paragraph">
            I&apos;m a seasoned UX designer based in Hamburg with a wealth of
            experience gained from working with major enterprise clients
            through the agency I represent. My passion for design and visual
            aesthetics had been ingrained within me for as long as I can remember.
            My professional journey into design commenced when a friend approached
            me to craft a website. At that point I realized design transcends mere
            aesthetics; I delved deeper into the realm of user experience design,
            which captivated me. This led me to pursue postgraduate studies in
            the field, solidifying my expertise.
            <br />
            <br />
            I really enjoy data-driven design that supports my design decisions
            without forgetting the business aspects. I use tools that support
            research or work with data analysis. I also place a high value on
            good communication with both the business and development side.
            <br />
            <br />
            The values that guide my work are:
            <br />
            - curiosity and a desire for continuous development,
            <br />
            - mutual respect and cooperation based on it, in which differences
            add value to the project,
            <br />
            - focus on the goal, data driven design, design for the user based
            on business goals,
            <br />- decision-making step by step. If you want to create a new
            feature, first check with a small effort whether it will be
            interesting for users.
          </p>
        </div>
        <div className="about-text-skills">
          <div className="about-text-header">SKILLS</div>
          <div className="about-text-skills-table">
            <div className="about-text-skills-table-first-column">
              <ul className="about-text-details-list">
                <li>Figma</li>
                <li>FigJam</li>
                <li>Sketch</li>
                <li>Wireframes</li>
                <li>Design Systems</li>
                <li>Prototyping</li>
                <li>Use cases</li>
                <li>User flows</li>
                <li>Workshops</li>
                <li>Usability testing</li>
                <li>App architecture</li>
              </ul>
            </div>
            <div className="about-text-skills-table-second-column">
              <ul>
                <li>Personas</li>
                <li>Handover</li>
                <li>Design Thinking</li>
                <li>Zeplin</li>
                <li>InVision</li>
                <li>Teamwork</li>
                <li>Miro</li>
                <li>UX Research</li>
                <li>HTML/CSS</li>
                <li>Visual Design</li>
                <li>Adobe Suite (AE, PR, PS, AI)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="about-text-details">
          <div className="about-text-header">EXPERIENCE</div>
          <div className="about-text-details-detail">
            <p>
              <strong>UX / UI Designer</strong>
              <br /> zweigrad Design
            </p>
            <p className="about-text-text-paragraph">I&apos;m currently part of zweigrad design team where I&apos;m crafting my professional experience and collaborate with top global companies through agency partnerships. Leading design initiatives, I create user-centered interfaces that are aligned with client goals and resonate globally. This role has refined my ability to deliver effective design solutions across industries, from concept to execution.
            </p>
            <a href="https://zweigrad.de/work/?lang=en" target="_blank" rel="noopener noreferrer" className="about-link-zweigrad">see zweigrad projects</a>
            <p>
              <ul className="about-text-details-list">
                {[
                  'Designed wireframes, prototypes, and mockups',
                  'Demonstrated problem-solving skills',
                  'Led design workshops and ideation sessions',
                  'Presented designs to stakeholders effectively',
                  'Created user personas, journey maps and use cases',
                  'Collaborated with cross-functional teams',
                  'Implemented design principles',
                  'Contributed to design systems and style guides',
                  'Stayed updated with industry trends',
                  'Established visual directions for new projects',
                  'Developed new screen flows for enhanced user experiences',
                  'Expanded design systems with new elements and icons',
                  'Prepared infographics for data communication',
                  'Collaborated with international clients, considering cultural nuances',
                  'Worked in Agile envirement',
                ].map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </p>
          </div>
        </div>
        <div className="about-text-details">
          <div className="about-text-header">EDUCATION</div>
          <div className="about-text-details-detail">
            <p>
              <strong>User Experience</strong>
              <br /> Postgraduate Studies at University of Information
              Technology and Management in Rzeszow
            </p>
            <p>
              <ul className="about-text-details-list">
                {[
                  'Business analysis and digital product strategy planning',
                  'Psychological aspects of UX',
                  'Content planning',
                  'Information and interaction architecture design',
                  'Graphic design of digital products',
                  'Prototyping of interactive solutions',
                  'Usability research',
                  'Teamwork and interpersonal communication',
                  'Fundamentals of digital product project management',
                ].map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </p>
          </div>
          <div className="about-text-details-detail">
            <p>
              <strong>Management</strong>
              <br /> Master&apos;s degree at Rzeszow University of Technology
              <br />
              Grade: very good
              <br /> Specialization: Professional manager
            </p>
          </div>
        </div>
        <div className="about-text-details">
          <div className="about-text-header">LANGUAGES</div>
          <div className="about-text-details-detail">
            <p>
              <strong>German</strong>
              <br />
              Goethe-Zertifikat C1
            </p>
            <p>
              <strong>English</strong>
              <br />
              Professional working proficiency
            </p>
            <p>
              <strong>Polish</strong>
              <br />
              Native
            </p>
          </div>
        </div>
        <div className="about-text-details">
          <div className="about-text-header">ACHIEVEMENTS</div>
          <div className="about-text-details-detail">
            <p>
              <strong>Rector’s Award for academic achievements</strong>
              <br />
              Rzeszow University of Technology
            </p>
            <p>
              <strong>Vice Chairman on marketing communication</strong>
              <br />
              Student research group BRIEF
            </p>
            <p>
              <strong>Marketing Specialist</strong>
              <br />
              PRz racing team (racing.prz.edu.pl)
            </p>
          </div>
        </div>
        <div className="about-text-details">
          <div className="about-text-header">COURSES</div>
          <div className="about-text-details-detail">
            <p>
              <strong>Academy of Skills AIESEC Rzeszów</strong>
              <br />
              AIESEC
            </p>
          </div>
        </div>
      </div>
      <BackHome />
    </div>
  );
}
