import React from 'react';
import './CaseDescription.scss';

export function CaseDescription({
  img,
  duration,
  tools,
  deliverables,
}: {
  img?: string;
  duration: string;
  tools?: string;
  deliverables?: string;
}) {
  return (
    <div className="case-description">
      <div className="case-description-header">
        <div className="bold">Project type</div>
        <div className="verdana">Individual project</div>
        {deliverables && (
          <>
            <div className="bold">Deliverables</div>
            <div className="verdana">{deliverables}</div>
          </>
        )}
        <div className="bold">Duration</div>
        <div className="verdana">{duration}</div>
        {tools && (
          <>
            <div className="bold">Tools</div>
            <div className="verdana">{tools}</div>
          </>
        )}
      </div>
      <div className="case-description-content">
        {img ? (
          <img
            src={img}
            className="case-description-content-image"
            alt="Mobile overview"
          />
        ) : null}
      </div>
    </div>
  );
}

CaseDescription.defaultProps = { img: null, deliverables: null, tools: null };
