import React from 'react';
import './CaseThumbnail.scss';

function renderImage(image: string, alt: string) {
  return <img src={image} alt={alt} className="thumbnail-image" />;
}

function renderDescription(
  header: string | React.ReactElement,
  description: string | React.ReactElement,
) {
  return (
    <div className="thumbnail-text">
      <div className="thumbnail-text-header">{header}</div>
      <div className="thumbnail-text-description">{description}</div>
      <div className="thumbnail-text-link">show</div>
    </div>
  );
}

export function CaseThumbnail({
  header,
  description,
  image,
  alt,
  rotated,
}: {
  header: string | React.ReactElement;
  description: string | React.ReactElement;
  image: string;
  alt: string;
  rotated?: boolean;
}) {
  return !rotated ? (
    <div className="thumbnail">
      {renderImage(image, alt)}
      {renderDescription(header, description)}
    </div>
  ) : (
    <div className="thumbnail rotated">
      {renderDescription(header, description)}

      {renderImage(image, alt)}
    </div>
  );
}

CaseThumbnail.defaultProps = { rotated: false };
