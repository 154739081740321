import React from 'react';
import './Footer.scss';

export function Footer() {
  return (
    <div className="footer">
      <div className="email">aleksandrach@protonmail.com</div>
      <div>
        <a
          href="https://www.linkedin.com/in/aleksch/"
          rel="noreferrer"
          target="_blank"
        >
          linkedIn
        </a>
      </div>
    </div>
  );
}
