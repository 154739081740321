import { ICaseRow } from '../../interfaces';
import mobile1 from '../../assets/art/mobile1.jpeg';
import mobile2 from '../../assets/art/mobile2.jpeg';
import mobile3 from '../../assets/art/mobile3.jpeg';
import mobile4 from '../../assets/art/mobile4.jpeg';
import mobile5 from '../../assets/art/mobile5.jpeg';
import mobile6 from '../../assets/art/mobile6.jpeg';
import mobile7 from '../../assets/art/mobile7.jpeg';
import analog1 from '../../assets/art/analog1.jpeg';
import analog2 from '../../assets/art/analog2.jpeg';
import analog3 from '../../assets/art/analog3.jpeg';
import analog4 from '../../assets/art/analog4.jpeg';
import analog5 from '../../assets/art/analog5.jpeg';
import analog6 from '../../assets/art/analog6.jpeg';
import sketch1 from '../../assets/art/sketch1.jpeg';
import sketch2 from '../../assets/art/sketch2.jpeg';
import sketch3 from '../../assets/art/sketch3.jpeg';
import design1 from '../../assets/art/design1.jpg';
import manipulation1 from '../../assets/art/manipulation1.jpeg';

export const ART_CONFIG: ICaseRow[] = [
  {
    header: 'mobile photography',
  },
  {
    image: mobile1,
  },
  {
    image: mobile2,
  },
  {
    image: mobile3,
  },
  {
    image: mobile4,
  },
  {
    image: mobile5,
  },
  {
    image: mobile6,
  },
  {
    image: mobile7,
  },
  {
    header: 'analog photography',
  },
  {
    image: analog1,
  },
  {
    image: analog2,
  },
  {
    image: analog3,
  },
  {
    image: analog4,
  },
  {
    image: analog5,
  },
  {
    image: analog6,
  },
  {
    header: 'drawings',
  },
  {
    image: sketch1,
  },
  {
    image: sketch2,
  },
  {
    image: sketch3,
  },
  {
    header: 'graphic design',
  },
  {
    image: design1,
  },
  {
    header: 'photo manipulation',
  },
  {
    image: manipulation1,
  },
];
