import React from 'react';
import { CaseRow } from '../../components/CaseRow/CaseRow';
import { CaseHeader } from '../../components/CaseHeader/CaseHeader';
import { CaseDescription } from '../../components/CaseDescription/CaseDescription';
import { FIXLY_TRUST_CONFIG } from './config';
import { BackHome } from '../../components/BackHome/BackHome';

export function FixlyTrust() {
  return (
    <div className="case-wrapper">
      <CaseHeader
        description='Increase Fixly users trust in cleaning services by "Fixly Trust"'
        bold
      />
      <CaseDescription duration="1 day" deliverables="Proposing a solution" />
      {FIXLY_TRUST_CONFIG.map((config, index) => (
        <CaseRow
          // eslint-disable-next-line react/no-array-index-key
          key={`travel-crew-${index}`}
          header={config.header}
          headerImage={config.headerImage}
          text={config.text}
          image={config.image}
          zoomImage={config.zoomImage}
          customContent={config.customContent}
          isCaseHeader={config.isCaseHeader}
          imageDescription={config.imageDescription}
        />
      ))}
      <BackHome />
    </div>
  );
}
