/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import React from 'react';
import { ICaseRow } from '../../interfaces';
import discover1 from '../../assets/fixly-trust/discover_1.png';
import discover2 from '../../assets/fixly-trust/discover_2.png';
import { PointList } from '../../components/PointList/PointList';

const headerText: string =
  'Fixly is a startup offering various services. ' +
  'The goal is to earn the trust of customers interested ' +
  'in cleaning services. The research will aim to find out under what situations users currently' +
  ' use the offer, what are their habits. We will try to understand what obstacles may stand in the' +
  'way of expanding the service.';

const discoverText: string =
  'Fixly is an extension of the olx portal,' +
  'that has been successful in the local market. Olx handles local advertisement' +
  'for buying, selling, renting, job offers. Fixly refers to a variety of services such' +
  'as plumbing, construction, removal of belongings up to the organization of parties.' +
  'You post an ad and wait for the company to come forward with an offer. Contact follows' +
  'a consistent pattern. After the assignment is completed, the contractors are evaluated.' +
  'They are awarded stars and comments.';

export const FIXLY_TRUST_CONFIG: ICaseRow[] = [
  {
    header: 'THE CHALLENGE',
    isCaseHeader: true,
    text: headerText,
  },
  {
    header: 'DISCOVER',
    isCaseHeader: true,
    image: discover1,
  },
  { text: discoverText },
  {
    image: discover2,
  },
  {
    text: 'On the page above, you can see that the average rating for contractors is 4.8, and the average wait time for a response is 6 minutes.',
  },
  {
    header: 'User interviews',
    text: (
      <span>
        To find out what are the reasons for less interest in cleaning services
        and how we can change it,
        <br />I decided to conduct a qualitative research.
        <br />
        I have divided my questions according to familiar convention into four
        parts. <br />
        Each of them has its own purpose.
        <br />
      </span>
    ),
    customContent: (
      <>
        <div className="sub-header">About the user</div>
        <PointList
          regular
          list={[
            'What do you do for a living?',
            'What does your typical day look like during the week?',
            'How do you usually spend your weekends?',
            'What kind of rest you prefer?',
            'Do you have a lot of responsibilities for running your home?',
          ]}
          intro={
            <div>
              In this section I tried to understand my users better. Who they
              are, what their habits are.
              <br />
              <br />
            </div>
          }
        />
        <div className="sub-header">General product questions</div>
        <PointList
          regular
          list={[
            'Have you used the Fixly platform?',
            'Does anything trigger your flustrations using these types of platforms?',
            'Have you already used a cleaning service on such a platform?',
            'Have you had something go wrong when you or perhaps someone you know ordered cleaning services?',
            'Is there anything that would make you more interested in using cleaning companies?',
            'What criteria are important to you when choosing a cleaning service?',
            'Do you trust the ratings of other users?',
          ]}
          intro={
            <div>
              These are open-ended questions about the product. They should not
              suggest answers to users.
              <br />
              <br />
            </div>
          }
        />
        <div className="sub-header">Specific questions</div>
        <PointList
          regular
          list={[
            'What is usually included in the description of janitorial services?',
            'What from the service description is critical to your decision?',
            'What assurance that the company is trustworthy would you find satisfying?',
            'What guarantee from the service would convince you to use these services?',
            'Would you be interested in monitoring the activities of a cleaning company?',
            'Did something make you abandon the cleaning service?',
          ]}
          intro={
            <div>
              These questions may deepen the ones asked in the previous section
              that we found interesting.
              <br />
              They can also be based on business goals. Sometimes they are a
              result of previous interviews.
              <br />
              <br />
            </div>
          }
        />
        <div className="sub-header">Closing questions</div>
        <PointList
          regular
          list={[
            'If you could create a feature yourself what would it be?',
            'Did something make you abandon the cleaning service?',
            'Do you have any questions for me?',
            'Can we contact you if we have any questions?',
          ]}
          intro={
            <div>
              This is the part where you may want to go back to something. You
              may have taken notes <br />
              and want to ask about something in more detail. Here is the time
              for user suggestions.
              <br />
              <br />
            </div>
          }
        />
      </>
    ),
  },
  { header: 'DELIVER', isCaseHeader: true },
  {
    header: 'Analysis of the answers and solution proposal',
    customContent: (
      <>
        Interviews with users provided information about their worries about
        leaving housing available
        <br />
        to cleaning crews. Users indicated that they were concerned about
        possible theft.
        <br />
        User feedback plays a major role in earning trust.
        <br />
        A large role in gaining trust is the recommendation of the service,
        which shows that the company is trusted.
        <br />
        From the research we can conclude that user feedback should be the basis
        for earning the badge of trust.
        <br />
        Users would be assured that the actions of cleaning crews are monitored
        and verified. <br />
        Collecting user feedback, having it verified by Flixly, and issuing a
        corresponding
        <br />
        <strong>trust certificate called &quot;Fixly Trust&quot;</strong> should
        encourage users to use cleaning services more often.
        <br />
      </>
    ),
  },
  {
    header: 'A/B tests and further project development',
    customContent: (
      <>
        To determine if a trust certificate contributes to interest in a
        service, we can conduct <strong>A/B testing</strong>.<br /> They can
        divide users in this case into two equal groups, although it is not
        always advisable.
        <br />
        In this case the risk of failure is low. We focus more on determining
        how much impact
        <br /> our proposal has on improving the number of orders.
        <br />
        <br /> The problem that can arise is the difficulty for new companies
        that don&apos;t have many user reviews to get
        <br /> their first orders. There may be a need to introduce a mechanism
        for new services providers.
      </>
    ),
  },
];
