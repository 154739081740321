import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../const/routes';

import './BackHome.scss';

export function BackHome() {
  return (
    <div className="back-home">
      <NavLink to={AppRoutes.ROOT}>back to home</NavLink>
    </div>
  );
}
