import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { CASE_STUDY_ROUTES, AppRoutes } from '../../const/routes';
import './Navigation.scss';

function setActiveColor(
  isActive: boolean,
  isProject = false,
): { color?: string } {
  const { pathname } = useLocation();

  return isActive || (isProject && CASE_STUDY_ROUTES.includes(pathname))
    ? { color: 'rgba(0, 0, 0, 0.8)' }
    : {};
}

export function Navigation() {
  return (
    <div className="navigation">
      <div className="navigation-links">
        <NavLink
          to={AppRoutes.ROOT}
          className="navigation-links-link"
          style={({ isActive }) => setActiveColor(isActive, true)}
        >
          my work
        </NavLink>
        <NavLink
          to={AppRoutes.ABOUT}
          className="navigation-links-link"
          style={({ isActive }) => setActiveColor(isActive)}
        >
          about me
        </NavLink>
      </div>
    </div>
  );
}
