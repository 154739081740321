import React from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MyWork } from './views/MyWork/MyWork';
import { About } from './views/About/About';
import { Navigation } from './components/Navigation/Navigation';
import { Footer } from './components/Footer/Footer';
import { OneStepCheckout } from './portfolio/OneStepCheckout/OneStepCheckout';
import { TravelCrew } from './portfolio/TravelCrew/TravelCrew';
import { InformationArchitecture } from './portfolio/InformationArchitecture/InformationArchitecture';
import { Strip } from './components/Strip/Strip';
import { ScrollTopButton } from './components/ScrollTopButton/ScrollTopButton';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { AppRoutes } from './const/routes';
import { Art } from './portfolio/art/Art';
import { FixlyTrust } from './portfolio/FixlyTrust/FixlyTrust';

export function App() {
  return (
    <div className="app">
      <ScrollTopButton />
      <BrowserRouter>
        <div className="app-wrapper">
          <Navigation />
          <ScrollToTop>
            <Routes>
              <Route path={AppRoutes.ROOT} element={<MyWork />} />
              <Route
                path={AppRoutes.ONE_STEP_CHECKOUT}
                element={<OneStepCheckout />}
              />
              <Route path={AppRoutes.TRAVEL_CREW} element={<TravelCrew />} />
              <Route
                path={AppRoutes.INFORMATION_ARCHITECTURE}
                element={<InformationArchitecture />}
              />
              <Route path={AppRoutes.FIXLY_TRUST} element={<FixlyTrust />} />
              <Route path={AppRoutes.ART} element={<Art />} />
              <Route path={AppRoutes.ABOUT} element={<About />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </div>
        <Strip />
      </BrowserRouter>
    </div>
  );
}
