import React from 'react';
import './Context.scss';

export function Context() {
  return (
    <div className="travel-crew-context">
      <div className="travel-crew-context-comparison">
        <div className="travel-crew-context-comparison-pros">
          <div className="travel-crew-context-comparison-pros-header">PROS</div>
          <div className="travel-crew-context-comparison-pros-list">
            <div>“Chat with owners.”</div>
            <div>“All in one place.”</div>
            <div>“Simple. Readable.”</div>
            <div>“Everything is handled by an app on your phone.”</div>
            <div>“The offline versionis also useful.”</div>
            <div>“Cancellation option.”</div>
            <div>“Quick answers to questions.”</div>
            <div>“Huge choice, easy and clear.”</div>
            <div>“Quickly gaining levels with numerous promotions.”</div>
            <div>“Fast efficient search.”</div>
            <div>“Super precision.”</div>
          </div>
        </div>
        <div className="travel-crew-context-compraison-cons">
          <div className="travel-crew-context-comparison-cons-header">CONS</div>
          <div className="travel-crew-context-comparison-cons-list">
            <div>
              “There is no contact to hotels, to make sure if a dog is allowed
              in the room.”
            </div>
            <div>“Photos are mixed, lack of information.”</div>
            <div>
              “No option to add animals to the search engine, no fees for
              animals.”
            </div>
            <div>
              “It is not possible to shorten the stay without permission in the
              app, but the contact is difficult, there should be such an option
              in the app.”
            </div>
            <div>“I miss some options in filters.”</div>
            <div>
              “It is not possible to sort the reviews, e.g. from the most
              recent...”
            </div>
            <div>“No option to extend the stay by a day or several days.”</div>
            <div>
              “It doesn&apos;t keep your messages or hotel address offline.”
            </div>
            <div>“Precise adjustment of the price slider is impossible.”</div>
          </div>
        </div>
      </div>
      <p className="text">
        Some solutions were well thought out in applications, while others
        showed room for improvement.
        <br /> They have potential, and I would like to explore this further
        with further questions.
      </p>
      <p className="text">
        I decided to concentrate on millennials and their individual demands for
        flexibility, efficiency and autonomy.
        <br /> My idea emerged from complaints received from the users.
        <br /> These users’ needs constituted the basis both for the design
        process and the selection of the features implemented.
      </p>
    </div>
  );
}
