import React from 'react';

import doubleDiamons from '../../assets/travel-crew/double-diamond-model.png';
import sketch from '../../assets/travel-crew/sketch.png';
import userFlow from '../../assets/travel-crew/user-flow.png';
import navigation from '../../assets/travel-crew/navigation.png';
import emmaPhoto from '../../assets/travel-crew/emma.png';
import johnnyPhoto from '../../assets/travel-crew/johnny.png';
import wireframe from '../../assets/travel-crew/wireframe.png';
import bwprototype1 from '../../assets/travel-crew/bwprototype1.gif';
import bwprototype2 from '../../assets/travel-crew/bwprototype2.gif';
import prototype1 from '../../assets/travel-crew/prototype1.gif';
import prototype2 from '../../assets/travel-crew/prototype2.gif';
import prototype3 from '../../assets/travel-crew/prototype3.gif';
import prototype4 from '../../assets/travel-crew/prototype4.gif';
import prototype5 from '../../assets/travel-crew/prototype5.gif';
import { Context } from './Context';
import { PointOfView } from './PointOfView';
import { Listen } from './Listen';
import { ICaseRow } from '../../interfaces';
import { UserNeeds } from './UserNeeds';
import { Persona } from './Persona';
import { DesignSystem } from './DesignSystem';
import { Prototypes } from '../../components/Prototypes/Prototypes';

export const TRAVEL_CREW_CONFIG: ICaseRow[] = [
  {
    header: 'THE CHALLENGE',
    isCaseHeader: true,
    text: 'How can we improve the available booking and communication support intravel apps to have an impact on user acquisition, retention, engagement, sales or other attributes.',
  },
  {
    header: 'My design process',
    text: 'For this design challenge, I followed the double diamond process.',
    image: doubleDiamons,
    imageDescription: (
      <span>
        The Double Diamond Model. 2015. From Rosala, Maria. “The Discovery Phase
        in UX Projects”. <br />
        https://www.nngroup.com/articles/discovery-phase/
      </span>
    ),
  },
  {
    header: 'DISCOVER',
    isCaseHeader: true,
    text: 'To begin with, I decided to look at the current most popular apps related to travel support and user reviews of them.',
  },
  {
    header: 'Get some context',
    text: 'I also read the comments about the apps and chose the ones that came up most often or seemed relevant to me.',
    customContent: <Context />,
  },
  {
    header: 'Listen to the people',
    text: (
      <span>
        To have a deep, free-flowing conversation with participants, I decidedto
        prepare
        <br /> an interview guide to conduct a semistructured UX Interview.
      </span>
    ),
    customContent: <Listen />,
  },
  {
    header: 'DEFINE',
    isCaseHeader: true,
  },
  {
    header: 'Create a personas',
    text: (
      <span>
        I imagined my user as a real person who has a job, a family, interests.{' '}
        <br /> I tried to look for their real needs.
      </span>
    ),
  },
  {
    headerImage: emmaPhoto,
    customContent: (
      <Persona
        name="Emma Cham"
        description={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <>
            Emma is a 29 year old employee of one of the larger e-commers in the
            region. Her job involves developing sales analysis,
            <br /> creating reports and analysing trends. Despite a long working
            day, she tries to devote time to sport and run in the evenings.
            <br /> She enjoys spending time with friends, attending cultural
            events. She is interested in other cultures.
            <br /> Recently, she started taking Spanish lessons. <br />
            She is curious about other cultures and meeting people. She loves to
            travel. Especially alone.
            <br /> She is then open to learning about people, customs and
            cuisine.
          </>
        }
        jobs={[
          'She adapts her trip to the situation. She likes to remain open to new possibilities.',
          "She doesn't want to spend too much time on it.",
          'She wants to be informed by local restaurants and places that showcase local cuisine and culture and lifestyle.',
          'Emma is on a tight budget when she travels. She wants to be informed of the final price at no extra charge. Online payment system.',
          'When travelling, it does not always have internet. She wants to be able to ask for details and be able to receive an answer later.',
          'Is open to information about events and additional facilities at the hotel and wants to be able to book them online.',
        ]}
        gains={[
          'Enthusiast of travel, suing places, people.',
          'Contact with local guides, their tips, their style is a real value for her.',
          'It is oriented towards immersion in local culture, a world different from everyday life.',
        ]}
        pains={[
          'No time to read reviews. Needs a clear ranking.',
          'Problems in making contact with the owner.',
          'Problem with internet availability.',
          'Unflexible terms of stay.',
          'Hidden charges.',
        ]}
        cases={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span>
            Emma would like an app that allows her to book and manage
            reservations efficiently while traveling.
            <br /> She wants to be able to get everything done through the app
            when she is at the coffee shop.
            <br /> She expects to find opportunities in the app to learn more
            about the local culture,
            <br /> book a tour recommended by others. She is geared towards
            exploring and active sightseeing. <br />
            Likewise, she would like to be supported in this.
          </span>
        }
      />
    ),
  },
  {
    headerImage: johnnyPhoto,
    customContent: (
      <Persona
        name="Johnny Willson"
        description={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span>
            Johnny is a 42-year tech lead at an information technology company.
            He spends his vacations with his two children and wife. <br />
            He desires recreation and interesting activities for the children.
            He himself is quiet and loves nature. <br />
            He also enjoys spending time in nature. He is happy when the whole
            family is happy. <br />
            He wants his vacation to be as well-organized as possible.
            <br />
            However, he does not use travel agencies because they do not give
            him enough control over the possible choices.
          </span>
        }
        jobs={[
          'He wants the holiday to be adapted to the needs of each member of the family.',
          'He likes to make his own choices, but is willing to take hints.',
          'It is important for him to have a good service.',
          "He doesn't like to be surprised, likes to manage his own time and have many options to choose from.",
        ]}
        gains={[
          'Attractions response to the needs of family members.',
          "The reception's answer to all your needs.",
          'Recommendation of reliable restaurants and table reservation option.',
        ]}
        pains={[
          "If children's activities are not provided during the vacation or are not enjoyable for children.",
          'Despite the hotel with 24-hour are problems with communicating with the reception desk.',
        ]}
        cases={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <span>
            He needs an app that comprehensively handles his holiday trip and is
            customized for his entire family. <br />
            An app that will keep him reliably in touch with the front desk.
            Having children and family sometimes causes surprises
            <br /> for parents during the day.
            <br /> He still wants lots of options and the ability to decide.
          </span>
        }
      />
    ),
  },
  {
    header: 'Define user needs',
    text: (
      <span>
        The jobs-to-be-done framework focuses instead on a change of behavior
        and on customer needs. <br />
        The point of departure is: “Why do customers use a product?”
      </span>
    ),
    customContent: <UserNeeds />,
  },
  {
    header: 'Get a point of view',
    text: 'Based on the earlier stage, I got the following overview of the app.',
    customContent: <PointOfView />,
  },
  {
    header: 'DEVELOP',
    isCaseHeader: true,
    text: 'After the define phase, I started sketching the first views.',
  },
  {
    header: 'Previous sketches',
    text: (
      <span>
        It quickly became apparent that sketching the views raised further
        questions. <br />I develop selected design issues that I came across
        during initial sketches using user flow.
      </span>
    ),
    image: sketch,
  },
  {
    header: 'User flow',
    text: (
      <span>
        The user flow describes the steps and choices taken by the user.
        <br />
        Allows you to concentrate on the action taken by the user.
        <br />
        It allows you to discover the paths for the user to move through the
        application.
        <br />
        It takes into account not only the most optimal user choices for the
        application,
        <br />
        but also considers other possibilities and turning points.
      </span>
    ),
    image: userFlow,
  },
  {
    header: 'Information Architecture',
    text: (
      <span>
        This stage should test our assumptions, as we are just creating the
        framework on which the next steps <br /> of application development will
        be based. It allows you to create categories and collections of
        information.
        <br /> <br />
        At this stage, we can perform competitive analysis for navigation, card
        sorting and tree testing. <br /> It checks how users understand <br />
        the categories we have created and how they assign information to them.
      </span>
    ),
    zoomImage: navigation,
  },
  {
    header: 'Wireframe',
    text: (
      <span>
        In the next step, I put the previous sketches, user flow and information
        architecture side by side.
        <br /> All of this together allowed me to create wireframes. Wireframe
        includes simple boxes and text to express the concept.
        <br /> Initially, I created basic screens. Wireframes should contain
        only shadesof gray, simple shapes.
        <br /> They make us focus on the layout of the elements, adequate space
        and functionality.
        <br /> Professionally created, they provide an excellent basis for
        presenting to stakeholdersand discussing products.
      </span>
    ),
    image: wireframe,
  },
  {
    header: 'Prototype',
    text: (
      <span>
        Creating the main screens made me think more deeply about the
        interactions.
        <br /> In other words, I was thinking about the steps that will take
        place during the various actions performed in the application.
        <br /> To keep it simple and clear, clickable prototypes of individual
        tasks are built.{' '}
      </span>
    ),
    customContent: <Prototypes prototypes={[bwprototype1, bwprototype2]} />,
  },
  {
    header: 'Design system',
    customContent: <DesignSystem />,
  },
  {
    header: 'DELIVER',
    isCaseHeader: true,
    text: (
      <span>
        Aesthetics, application consistency and functionality are also important
        at this stage.
        <br /> Prototypes can be used for user testing as they give the
        impression of a working application.
        <br /> You can see examples of how the app is used below.
      </span>
    ),
    customContent: (
      <Prototypes
        prototypes={[
          prototype1,
          prototype2,
          prototype3,
          prototype4,
          prototype5,
        ]}
      />
    ),
  },
  {
    header: (
      <span>
        Conclusions
        <br /> and further
        <br /> developments
      </span>
    ),
    text: (
      <span>
        During the creating process I tried to think about the goal, keep the
        project simple. <br />I set time frames and kept the most important
        goals in mind. I did not lose sight of the fact that I create
        <br /> a product for users in order to achieve the assumed business
        goals. An important evolving premise
        <br /> is creating products and making design decisionsbased on data and
        research.
        <br /> In the future, I would focus on finding omissions, improving
        effectiveness, developing the reception <br />
        communication system and themed vacations.
      </span>
    ),
  },
];
