import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../const/routes';
import './Strip.scss';

enum StripeColors {
  MAIN = '#F4EBE6',
  CASE_STUDY = '#CFEBEB',
  ABOUT = '#FFC48E',
  INFORMATION_ARCHITECTURE = '#C9E6EC',
  TRAVEL_CREW = '#C4E9E0',
  FIXLY_TRUST = '#FFD890',
  ART = '#EBC0FF',
}

function setStripColor() {
  const { pathname } = useLocation();

  if (pathname === `/${AppRoutes.ABOUT}`) {
    return { backgroundColor: StripeColors.ABOUT };
  }

  if (pathname === `/${AppRoutes.INFORMATION_ARCHITECTURE}`) {
    return { backgroundColor: StripeColors.INFORMATION_ARCHITECTURE };
  }

  if (pathname === `/${AppRoutes.TRAVEL_CREW}`) {
    return { backgroundColor: StripeColors.TRAVEL_CREW };
  }

  if (pathname === `/${AppRoutes.FIXLY_TRUST}`) {
    return { backgroundColor: StripeColors.FIXLY_TRUST };
  }

  if (pathname === `/${AppRoutes.ART}`) {
    return { backgroundColor: StripeColors.ART };
  }

  return { backgroundColor: StripeColors.MAIN };
}

export function Strip() {
  return <div className="strip" style={setStripColor()} />;
}
