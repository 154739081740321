import React from 'react';
import './DesignSystem.scss';
import colorPalette from '../../assets/travel-crew/color-palette.png';
import typography from '../../assets/travel-crew/typography.png';
import inputs from '../../assets/travel-crew/inputs.png';
import cards from '../../assets/travel-crew/cards.png';

export function DesignSystem() {
  return (
    <div className="design-system">
      <p className="text">
        A design system is a collection of elements containing information about
        the visual sides of an application.
        <br /> To the design system belong color palette, information about used
        fonts, used grids, icons, buttons, inputs,
        <br /> cards, search elements, lists and many others. It is a collection
        of structured information, which,
        <br /> if well described, facilitates the work of the whole team. In
        subsequent stages of the application development,
        <br /> it is a starting point for the creation of further elements.
        <br />
        Simple design elements were used to extend the system and create more
        complex elements such as cards or lists.
      </p>
      <p className="text">
        I have included the elements of the design system below.
      </p>

      <img
        src={colorPalette}
        alt="Color palette"
        className="design-system-image"
      />
      <img src={typography} alt="Typography" className="design-system-image" />
      <img src={inputs} alt="Inputs" className="design-system-image" />
      <img src={cards} alt="Cards" className="design-system-image" />
    </div>
  );
}
