import React from 'react';
import './CaseHeader.scss';

export function CaseHeader({
  img,
  description,
  bold,
}: {
  img?: string;
  description: string;
  bold?: boolean;
}) {
  return (
    <div className="case-header">
      {img ? (
        <img src={img} className="case-header-image" alt={description} />
      ) : null}
      <div className="case-header-description">
        {bold ? <h2>{description}</h2> : description}
      </div>
    </div>
  );
}

CaseHeader.defaultProps = { img: null, bold: false };
