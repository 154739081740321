import React, { useState, useEffect } from 'react';
import './ScrollTopButton.scss';

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function ScrollTopButton({ top }: { top?: number }) {
  const [visible, setVisible] = useState(false);
  const onScroll = () => {
    setVisible(document.documentElement.scrollTop > (top || 50));
  };
  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return visible ? (
    <button onClick={() => scrollToTop()} type="button" className="scroll-top">
      up
    </button>
  ) : null;
}

ScrollTopButton.defaultProps = { top: 30 };
