import React from 'react';

export function PointOfView() {
  return (
    <div className="travel-crew-point-of-view">
      <p className="text">
        Travel Crew is an app for people who want to get information about
        potential accommodations
        <br /> in a simple and clear way. And manage their accommodations and
        attractions while traveling.
      </p>
      <p className="text">
        There are already apps where you can find and book accommodation.
        However, this app is different from its competitors.
        <br /> It introduces an emphasis on improving what is currently facing
        criticism from users. <br /> Besides, it includes new elements such as
        stay management,
        <br /> a communication panel with the owner and access to recommended
        places and attractions for users.
      </p>
      <p className="text">
        From the interviews conducted with users, it is clear that their needs
        differ significantly.
        <br /> This is dictated by their living situation and age. That&apos;s
        why themed vacations have been created.
        <br /> A large group of users indicates a desire to collect points and
        badges.
        <br /> There was created a map on which users can mark places visited,
        collect badges.
        <br /> The loyalty program created by the badges contains elements of
        gamification.
      </p>
    </div>
  );
}
