import React from 'react';
import { PointList } from '../../components/PointList/PointList';
import './Persona.scss';

export function Persona({
  name,
  description,
  jobs,
  gains,
  pains,
  cases,
}: {
  name: string;
  description: string | React.ReactElement;
  jobs: string[];
  gains: string[];
  pains: string[];
  cases: string | React.ReactElement;
}) {
  return (
    <div className="persona">
      <div className="persona-name">{name}</div>
      <div className="persona-description">{description}</div>
      <div className="sub-header">JOBS TO BE DONE</div>
      <PointList regular list={jobs} />
      <div className="sub-header">GAINS</div>
      <PointList regular list={gains} />
      <div className="sub-header">PAINS</div>
      <PointList regular list={pains} />
      <div className="sub-header">USE CASES</div>
      <div className="persona-cases">{cases}</div>
    </div>
  );
}
