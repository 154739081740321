import React from 'react';
import prototype1 from '../../assets/travel-crew/prototype1.gif';
import sketch1 from '../../assets/art/sketch1.jpeg';
import informationThumbnail from '../../assets/information-architecture/information-architecture-thumbnail.png';
import fixlyThumbnail from '../../assets/fixly-trust/fixly-trust.png';
import zweigradLogo from '../../assets/zweigrad/zweigrad.jpg';
import { AppRoutes } from '../../const/routes';

interface IMyWorkConfig {
  rotated?: boolean;
  externalLink?: string;
  route?: AppRoutes;
  header: string | React.ReactElement;
  image: string;
  alt: string;
  description: string | React.ReactElement;
}

export const MY_WORK_CONFIG: IMyWorkConfig[] = [
  {
    route: AppRoutes.TRAVEL_CREW,
    header: 'Case study 1: travel crew',
    image: prototype1,
    alt: 'Travel crew project',
    description:
      'Enriches the travel experience, contacting the front desk and accessing additional services in the app.',
  },
  {
    rotated: true,
    route: AppRoutes.INFORMATION_ARCHITECTURE,
    header: (
      <span>
        Case study 2: Information architecture
        <br /> for a jewelry store
      </span>
    ),
    image: informationThumbnail,
    alt: 'Information architecture for a jewelry store',
    description: (
      <span>
        The process of researching and creating
        <br />a new information architecture.
      </span>
    ),
  },
  {
    route: AppRoutes.FIXLY_TRUST,
    header: (
      <span>
        Case study 3: Increase Fixly users trust
        <br /> in cleaning services by &quot;Fixly Trust&quot;
      </span>
    ),
    image: fixlyThumbnail,
    alt: 'Increase Fixly users trust in cleaning services',
    description: <span>A user interview creating process.</span>,
  },
  {
    rotated: true,
    externalLink: 'https://zweigrad.de/work/?lang=en',
    header: 'My professional experience',
    image: zweigradLogo,
    alt: 'Professional experience',
    description: 'zweigrad design projects',
  },
  {
    route: AppRoutes.ART,
    header: 'Drawings and photos',
    image: sketch1,
    alt: 'Drawings and photos',
    description: 'Drawings and photography I do in my spare time.',
  },
];

// TODO: Add more projects
// {
//   route: AppRoutes.ONE_STEP_CHECKOUT,
//   header: 'Creating one step check out',
//   image: travelCrew,
//   alt: 'Creating one step check out',
//   description:
//     'Step-by-step walkthrough on creation one step check out,'
//      + 'includes user jurney map or 8s method.',
// },
