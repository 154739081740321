import React from 'react';

export function Listen() {
  return (
    <div className="travel-crew-listen">
      <p className="text">
        I didn&apos;t ask many questions. I was guiding the topic and then
        listening carefully, observing what arouses their emotions.
        <br /> Furthermore, I let the conversation take shape and deepened the
        threads that interested me.{' '}
      </p>
      <p className="text">
        That allowed me to go further in creating personas.
      </p>
    </div>
  );
}
