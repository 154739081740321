import React from 'react';
import { Link } from 'react-router-dom';
import { CaseThumbnail } from '../../components/CaseThumbnail/CaseThumbnail';
import { MY_WORK_CONFIG } from './const';
import './MyWork.scss';

export function MyWork() {
  return (
    <div className="my-work">
      <div className="my-work-header">
        I am UX / UI designer with love for simplicity.
      </div>
      <div className="my-work-thumbnail-links">
        {MY_WORK_CONFIG.map(
          ({ route, header, image, alt, description, rotated, externalLink }) => (
            externalLink
              ? (
                <a href="https://zweigrad.de/work/?lang=en" target="_blank" rel="noopener noreferrer">
                  <CaseThumbnail
                    header={header}
                    description={description}
                    image={image}
                    alt={alt}
                    rotated={rotated}
                  />
                </a>
              ) : (
                <Link to={route ?? ''} key={route}>
                  <CaseThumbnail
                    header={header}
                    description={description}
                    image={image}
                    alt={alt}
                    rotated={rotated}
                  />
                </Link>
              )
          ),
        )}
      </div>
    </div>
  );
}
