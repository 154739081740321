import React from 'react';
import './ItemsList.scss';

export function ItemsList({ columns }: { columns: Array<string[]> }) {
  return (
    <div className="items-list">
      {columns.map((column) => (
        <div>
          {column.map((item) => (
            <li>{item}</li>
          ))}
        </div>
      ))}
    </div>
  );
}
