import React from 'react';
import { CaseRow } from '../../components/CaseRow/CaseRow';
import { CaseHeader } from '../../components/CaseHeader/CaseHeader';
import { CaseDescription } from '../../components/CaseDescription/CaseDescription';
import { TRAVEL_CREW_CONFIG } from './config';
import travelCrewLogo from '../../assets/travel-crew/travel-crew-logo.png';
import prototype1 from '../../assets/travel-crew/prototype1.gif';
import { BackHome } from '../../components/BackHome/BackHome';

export function TravelCrew() {
  return (
    <div className="case-wrapper">
      <CaseHeader
        img={travelCrewLogo}
        description="An app that supports the entire travel experience."
      />
      <CaseDescription
        img={prototype1}
        duration="4 weeks"
        tools="Figma"
        deliverables="Mobile app mockups"
      />
      {TRAVEL_CREW_CONFIG.map((config, index) => (
        <CaseRow
          // eslint-disable-next-line react/no-array-index-key
          key={`travel-crew-${index}`}
          header={config.header}
          headerImage={config.headerImage}
          text={config.text}
          image={config.image}
          zoomImage={config.zoomImage}
          customContent={config.customContent}
          isCaseHeader={config.isCaseHeader}
          imageDescription={config.imageDescription}
        />
      ))}
      <BackHome />
    </div>
  );
}
