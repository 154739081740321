import React from 'react';
import { CaseRow } from '../../components/CaseRow/CaseRow';
import { CaseHeader } from '../../components/CaseHeader/CaseHeader';
import { CaseDescription } from '../../components/CaseDescription/CaseDescription';
import { INFORMATION_ARCHITECTURE_CONFIG } from './config';
import { BackHome } from '../../components/BackHome/BackHome';

export function InformationArchitecture() {
  return (
    <div className="case-wrapper">
      <CaseHeader
        description="Information architecture for a jewelry store"
        bold
      />
      <CaseDescription
        duration="1 day"
        deliverables="Navigation tree"
        tools="FigJam, Notion, Ux Metrics"
      />
      {INFORMATION_ARCHITECTURE_CONFIG.map((config, index) => (
        <CaseRow
          // eslint-disable-next-line react/no-array-index-key
          key={`information-architecture-${index}`}
          header={config.header}
          headerImage={config.headerImage}
          text={config.text}
          image={config.image}
          zoomImage={config.zoomImage}
          customContent={config.customContent}
          isCaseHeader={config.isCaseHeader}
        />
      ))}
      <BackHome />
    </div>
  );
}
