import React from 'react';

import { ICaseRow } from '../../interfaces';
import navigation from '../../assets/information-architecture/navigation.png';
import findability from '../../assets/information-architecture/findability.png';
import hybridSorting from '../../assets/information-architecture/hybrid-sorting.png';
import tree from '../../assets/information-architecture/tree.png';
import navigationTree from '../../assets/information-architecture/navigation-tree.png';
import { ItemsList } from '../../components/ItemsList/ItemsList';
import { PointList } from '../../components/PointList/PointList';

const GOALS = [
  'search for and buy jewelry and watches',
  'look for detailed information about jewelry in the form of articles and product descriptions',
  'will be looking for new collections',
  'want to look for jewelry for special occasions such as engagements, gifts',
  'will want to find sales',
  'will look for matching pieces to jewelry they already own, collections',
  'they will first choose the product category e.g. earrings, bracelets',
  'will search for products by selecting the type of raw material, e.g. gold, silver, diamond',
  'want to buy a gift card',
  'want to follow trends and inspiration',
  'want to find a stationary store',
  'want to create their own account in which: want to keep adress information, have a collection of favorite products, want to have information about the loyalty program, will want to have information about recommended products or special promotions',
];

const CONCLUSIONS_1 = [
  'users added new groups e.g.: company, jewelry properties, expert advises, brand, manufacturers, style',
  <span>
    users were not able to associate the property: water resistance with
    watches,
    <br /> therefore water resistance should be included in the filters for
    watches
  </span>,
  'a large part of the users matched the ring size to the customer service',
  'users do not distinguish between theme and collection',
  'users place watches in jewelry and not in a separate category, so there should be a watch subcategory created',
  'users associated the category of vouchers and gift cards with becoming a member of a club',
  <span>
    users categorize bestsellers and novelties as a subcategory in particular
    categories e.g. wedding,
    <br /> so there should be a subcategory of novelties for particular
    categories
  </span>,
  'users are looking for brands in the main categories',
  'users had problems with assigning the advice item, so there should be a separate tab for expert advice',
  'diamonds are directly associated with engagement',
  'users are confusing the engagement and wedding categories, so creating one categoryee',
];

const CONCLUSIONS_2 = [
  'study did not confirm that the bestseller card should be a separate category',
  'users do not understand the "mixed materials" category',
  'the creation of a separate tutorial category meant that users quickly completed the task correctly',
];

const CARDS_1 = [
  [
    'engagement rings',
    'premium jewelry',
    'collections',
    'bracelets',
    'classic - one',
    'diamond',
    '$500 - $999',
  ],
  [
    'jewelry gift',
    'gold ring with zircons',
    'silver cufflinks',
    'Top collections',
    'gold ring - Art Deco',
    'Scarlett',
  ],
  [
    'Delivery time and costs',
    'About us',
    'Gift card',
    'modern wedding ring designs',
    'Top collections',
    'jewelry gift',
  ],
];

const CATEGORIES = [
  ['Collections', 'Jewelry', 'Diamonds', 'Engagement', 'classic one diamond'],
  ['Motifs', 'Promotions', 'SALES', 'Vouchers', "Woman's Watches"],
  ['Help', 'Support', 'About us', 'gift cards ', 'Customer service'],
];

export const INFORMATION_ARCHITECTURE_CONFIG: ICaseRow[] = [
  {
    header: 'THE CHALLENGE',
    text: 'Creating navigation tree for jewelry online store.',
    isCaseHeader: true,
  },
  {
    header: 'DISCOVER',
    isCaseHeader: true,
  },
  {
    header: 'Understand user goals',
    text: (
      <span>
        {' '}
        At first, I wondered <strong>what will users do</strong> on the website
        and what are their goals?
      </span>
    ),
    customContent: <PointList list={GOALS} />,
  },
  {
    header: (
      <span>
        Competitive analysis
        <br /> of product findability
      </span>
    ),
    text: (
      <span>
        The next step was to explore{' '}
        <strong>how the user can find the required product.</strong>
        <br /> For this reason, I started to analyze the competitors&apos;
        websites. I chose 7 popular brands that sell jewelry.
        <br /> I have included my observations in the table.
      </span>
    ),
    image: findability,
  },
  {
    header: (
      <span>
        Competitive analysis
        <br /> for navigation
      </span>
    ),
    text: (
      <span>
        Now I&apos;ve started thinking about the available navigation methods
        that are used
        <br /> by the most popular jewelry sellers.
      </span>
    ),
    image: navigation,
  },
  {
    header: (
      <span>
        Defining the content
        <br /> and assortment
        <br /> of the store
      </span>
    ),
    text: (
      <span>
        As a next step, I thought about cards. The cards will be{' '}
        <strong>sorted by the research participants</strong>.<br /> The sample
        cards that we will give to the study participants will be used to find
        the answers to our questions.
        <br /> They will help you understand how users define and understand the
        category.
        <br /> And where users expect to find the information they are looking
        for. <br />
        As well, it&apos;s supposed to show how users name content. Perhaps they
        will create their own terms
      </span>
    ),
  },
  {
    header: 'Cards - single items',
    customContent: <ItemsList columns={CARDS_1} />,
  },
  {
    header: (
      <span>
        Organization <br /> and labels
      </span>
    ),
    text: (
      <span>
        In the next stage, I prepared materials for the testing, which will
        answer the question <br /> of{' '}
        <strong>
          how users group the products and services offered in the store.
        </strong>{' '}
        <br />
        In this way, we can discover which products users think have common
        features and criteria.
        <br /> In which departments users are looking for particular products.
      </span>
    ),
  },
  {
    header: (
      <span>
        Categories - group
        <br /> of products and services
      </span>
    ),
    customContent: <ItemsList columns={CATEGORIES} />,
  },
  {
    header: 'USER RESEARCH',
    isCaseHeader: true,
    text: (
      <span>
        It is a good idea to conduct the study several times. One time with a
        few users,
        <br /> then reflect on the conclusions.
        <br /> Next test should then be conducted to confirm or refute our
        changes.
        <br /> It is very important that users have some general knowledge of
        the field we are researching.
      </span>
    ),
  },
  {
    header: 'Hybrid card sorting',
    text: (
      <span>
        During the user testing I conducted with the tool{' '}
        <strong>ux metrics, users assigned cards to categories.</strong>
        <br /> The cards and categories were given to the users by me. Because
        the survey was in hybrid form,
        <br /> users were able to{' '}
        <strong>create new cards or categories</strong> that they thought were
        missing.
      </span>
    ),
    image: hybridSorting,
  },
  {
    header: 'Conclusions',
    customContent: <PointList list={CONCLUSIONS_1} />,
  },
  {
    header: 'Hierarchy tests',
    text: (
      <span>
        Hierarchy testing involves giving users short tasks to complete. They
        are supposed to find a product or a category.
        <br /> They search in the hierarchy tree given by us. At the end, the
        program presents a <strong>report</strong>.<br /> There is a information
        if <strong>the user found the product</strong> at all,{' '}
        <strong>how long</strong> it took
        <br /> and <strong>what was the way</strong> to find it.
      </span>
    ),
    image: tree,
  },
  {
    header: 'Conclusions',
    customContent: <PointList list={CONCLUSIONS_2} />,
  },
  { header: 'DELIVER', isCaseHeader: true },
  {
    header: 'Navigation tree',
    text: 'The research I conducted allowed me to build the navigation tree posted below.',
    zoomImage: navigationTree,
  },
];
