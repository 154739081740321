import React from 'react';
import { CaseRow } from '../../components/CaseRow/CaseRow';
import { CaseHeader } from '../../components/CaseHeader/CaseHeader';
import { ART_CONFIG } from './config';
import './Art.scss';
import { BackHome } from '../../components/BackHome/BackHome';

export function Art() {
  return (
    <div className="case-wrapper art">
      <CaseHeader description="Drawings and photography" bold />
      {ART_CONFIG.map((config, index) => (
        <CaseRow
          // eslint-disable-next-line react/no-array-index-key
          key={`art-${index}`}
          header={config.header}
          headerImage={config.headerImage}
          text={config.text}
          image={config.image}
          customContent={config.customContent}
        />
      ))}
      <BackHome />
    </div>
  );
}
