export enum AppRoutes {
  TRAVEL_CREW = 'travel-crew',
  INFORMATION_ARCHITECTURE = 'information-architecture',
  ONE_STEP_CHECKOUT = 'one-step-checkout',
  FIXLY_TRUST = 'fixly-trust',
  ABOUT = 'about',
  ART = 'art',
  ROOT = '/',
}

export const CASE_STUDY_ROUTES_ORIGINAL = [
  AppRoutes.TRAVEL_CREW,
  AppRoutes.ONE_STEP_CHECKOUT,
  AppRoutes.INFORMATION_ARCHITECTURE,
  AppRoutes.FIXLY_TRUST,
  AppRoutes.ART,
];

export const CASE_STUDY_ROUTES = CASE_STUDY_ROUTES_ORIGINAL.map(
  (route) => `/${route}`,
);
