import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import { ICaseRow } from '../../interfaces';

import './CaseRow.scss';

export function CaseRow({
  header,
  isCaseHeader,
  headerImage,
  text,
  image,
  imageDescription,
  customContent,
  zoomImage,
}: ICaseRow) {
  return (
    <div className="case-row" style={isCaseHeader ? { marginTop: '7rem' } : {}}>
      <div className={isCaseHeader ? 'case-row-header' : 'case-row-subheader'}>
        {headerImage ? (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img className="case-row-header-image" src={headerImage} />
        ) : (
          header
        )}
      </div>
      <div className="case-row-content">
        {text ? <div className="case-row-content-text text">{text}</div> : null}
        {image ? (
          <div className="case-row-content-image-container">
            <img
              src={image}
              alt={`Case ${imageDescription}`}
              className="case-row-content-image"
              style={{
                maxWidth: '100%',
              }}
            />

            {imageDescription ? (
              <div className="case-row-image-description">
                {imageDescription}
              </div>
            ) : null}
          </div>
        ) : null}
        {zoomImage ? (
          <div className="case-row-content-image-container">
            <Zoom>
              <img
                src={zoomImage}
                alt={`Case ${imageDescription}`}
                className="case-row-content-image"
                style={{
                  maxWidth: '100%',
                }}
              />
            </Zoom>

            {imageDescription ? (
              <div className="case-row-image-description">
                {imageDescription}
              </div>
            ) : null}
          </div>
        ) : null}
        {customContent}
      </div>
    </div>
  );
}

CaseRow.defaultProps = {
  header: '',
  isCaseHeader: false,
  headerImage: '',
  text: '',
  image: '',
  customContent: null,
  zoomImage: null,
};
