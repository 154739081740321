import React from 'react';
import './UserNeeds.scss';

const whenThenConfig = [
  {
    when: 'I look for local restaurants',
    want: 'have information on those verified',
    can: 'taste authentic local cuisine',
  },
  {
    when: 'I visit new places',
    want: 'a good local guide',
    can: 'have a good quality trip',
  },
  {
    when: 'I order extra service',
    want: 'be informed about options',
    can: 'book it easily anywhere',
  },
  {
    when: 'I adapt room services',
    want: 'get confirmation',
    can: 'plan other activities',
  },
  {
    when: "I'm looking for a restaurant or a guide",
    want: 'have a recommendation',
    can: 'be sure it is verified',
  },
  {
    when: 'planning my vacation',
    want: 'I want to do everything in one place',
    can: 'be sure not to forget anything',
  },
  {
    when: 'I contact owner',
    want: 'get in touch quickly',
    can: 'save time and effort',
  },
  {
    when: 'looking for a place to stay',
    want: 'have clear and full information',
    can: 'choose accommodation at short time',
  },
];

export function UserNeeds() {
  return (
    <div className="travel-crew-user-needs">
      {whenThenConfig.map((item) => (
        <div className="travel-crew-user-needs-row">
          <div className="travel-crew-user-needs-row-box">
            <div className="travel-crew-user-needs-row-header">WHEN</div>
            <div className="travel-crew-user-needs-row-text">{item.when}</div>
          </div>
          <div className="travel-crew-user-needs-row-box">
            <div className="travel-crew-user-needs-row-header">I WANT TO</div>
            <div className="travel-crew-user-needs-row-text">{item.want}</div>
          </div>
          <div className="travel-crew-user-needs-row-box">
            <div className="travel-crew-user-needs-row-header">SO I CAN</div>
            <div className="travel-crew-user-needs-row-text">{item.can}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
