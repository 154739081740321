import React from 'react';
import './PointList.scss';

export function PointList({
  list,
  regular,
  intro,
}: {
  list: (string | React.ReactElement)[];
  regular?: boolean;
  intro?: string | React.ReactElement;
}) {
  return (
    <ul className={regular ? 'point-list-regular' : 'point-list'}>
      {intro}
      {list.map((point, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`point-list-${index}`}>{point}</li>
      ))}
    </ul>
  );
}

PointList.defaultProps = { regular: false, intro: null };
