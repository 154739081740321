import React from 'react';
import './Prototypes.scss';

export function Prototypes({ prototypes }: { prototypes: string[] }) {
  return (
    <div className="prototypes">
      {prototypes.map((prototype) => (
        <img
          src={prototype}
          className="prototypes-prototype"
          alt="App prototype"
        />
      ))}
    </div>
  );
}
